import React from "react";
import { Link } from "gatsby";
import OurProducts_Hero from "../../../assets/img/ToolsResources2_Hero.jpg";
import cheques_and_cash_management from "../../../assets/img/cheques-and-banking.jpg";
import banking_products from "../../../assets/img/Banking_products.jpg";
import business_forms from "../../../assets/img/business-forms.jpg";
import custom_printing from "../../../assets/img/custom-printing.jpg";
import business_office_supplies from "../../../assets/img/BusinessOfficeSupplies_Thumbnail.jpg";
import marketing_products from "../../../assets/img/full-colour-print-marketing.jpg";
import signature_packaging from "../../../assets/img/signature-packaging-cat.jpg";
import calendars from "../../../assets/img/DFS_CA_Tools_Calendars.jpg";
import logo_design from "../../../assets/img/LogoDesignServices_Thumbnail.jpg";

import styles from "../style/styles.module.css";

export default function Products() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Our Products</h1>
          <h2>More ways to say 'yes' to your customers.</h2>
          <p className={styles.productParag}>
            Win business with our wide array of printed products, supplies and
            services.
          </p>
          <img
            className={styles.hero}
            src={OurProducts_Hero}
            alt="Three images of working professionals. From left to right: Construction worker, salesperson, mechanic."
          />

          <p className={styles.productMargin}>
            DFS is your one-stop source for proven product solutions.
          </p>
          <p className={styles.productMargin}>
            We offer wholesale pricing on the business supplies your customers
            need to manage and promote their operation more effectively.
          </p>
          <p className={styles.productMargin}>
            No matter what business they're in.
          </p>
          <hr className={styles.productHr} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Products to Manage Business</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img
                src={cheques_and_cash_management}
                alt="Cheques and cash management Image"
              ></img>
              <Link to="/products/business-cheques/">
                Cheques and Cash Management
              </Link>
            </div>
            <div className={styles.card}>
              <img src={banking_products} alt="Banking products Image"></img>
              <Link to="/products/banking-products/">Banking Products</Link>
            </div>
            <div className={styles.card}>
              <img src={business_forms} alt="Business Forms Image"></img>
              <Link to="/products/business-forms/">Business Forms</Link>
            </div>
            <div className={styles.card}>
              <img src={custom_printing} alt="Custom printing Image"></img>
              <Link to="/products/custom-printing/">Custom Printing</Link>
            </div>
            <div className={styles.card}>
              <img
                src={business_office_supplies}
                alt="Business & office supplies Image"
              ></img>
              <Link to="/products/office-supplies/">
                Business & Office Supplies
              </Link>
            </div>
          </div>
          <hr className={styles.productHr} />
        </div>
        <div className={styles.row}>
          <div className={styles.content}>
            <h2>Products to Promote Business</h2>
            <div className={styles.cards}>
              {/*    <div className={styles.card}>
                <img
                  src={signature_packaging}
                  alt="Signature packaging Image"
                ></img>
                <Link to="/products/signature-packaging/">
                  Signature Packaging
                </Link>
              </div> */}
              <div className={styles.card}>
                <img src={calendars} alt="Calendars Image"></img>
                <Link to="/products/holiday-cards-calendars/calendars">
                  Calendars
                </Link>
              </div>
              {/*<div className={styles.card}>
                <img src={logo_design} alt="Logo design services Image"></img>
                <Link to="/products/logo-design/">Logo Design Services</Link>
            </div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
