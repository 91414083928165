import React from "react";
import Layout from "../../other/layout";
import Products from "../../components/AC-StaticPages/products/Products";
import SEO from "../../other/seo";

export default function products() {
  return (
    <Layout>
      <SEO title="Products" />

      <Products />
    </Layout>
  );
}
